<template>
  <v-footer class="grey darken-4">
    <div class="row">
      <div class="col-12 text-center white--text">©{{ new Date().getFullYear() }} Mr Tiggle. All rights reserved.</div>
    </div>
  </v-footer>
</template>
<script>
  export default {};
</script>
